import { ProcessStep } from '../../ProcessEditor';
import { Alert, Button, Image, Modal } from 'react-bootstrap';
import React from 'react';
import { ClipboardMock, CopyToClipboard } from '../../CopyToClipboard';

export const SelfRegistrationModal = (props: {
  visible: boolean,
  onClose:()=>void,
  processSteps: ProcessStep[],
  registrationUrl: string,
  qrCodeUrl?: string,
  pdfUrl?: string,
  warnUserNotYetCreated: boolean
}) => {
  const clipboard: Clipboard = 'clipboard' in navigator
    ? navigator.clipboard as Clipboard
    : new ClipboardMock();

  const copyImgToClipboard = async (imgUrl: string | undefined) => {
    if (!imgUrl) return;
    try {
      const data = await fetch(imgUrl);
      const blob = await data.blob();
      await clipboard.write([new ClipboardItem({ [blob.type]: blob })]);
    } catch (err) {
      console.error(err);
    }
  };

  return <>
    <Modal show={props.visible} onHide={props.onClose} dialogClassName='self-registration-dialog'>
      <Modal.Header closeButton className=''>
        <h3>Purchaser portal Self-Registration</h3>
      </Modal.Header>
      <Modal.Body>
        <span>This QR Code contains a link that can be recognised by smartphones that will enable a party to self-register and complete the following actions remotely:</span>
        <ul className={'mt-2'}>
          {props.processSteps?.filter(step => step.enabled && !step.hidden)?.map(step => <li>{step.description}</li>)}
        </ul>
        <div className={'mt-4 d-flex flex-column align-items-center'}>
          <Image src={props.qrCodeUrl}></Image>
          <CopyToClipboard text={'Copy QR Code to clipboard'} copyCallback={()=> copyImgToClipboard(props.qrCodeUrl)} />
        </div>
        <div className={'mt-4 d-flex flex-column align-items-center'}>
          <CopyToClipboard text={'Copy URL to clipboard'} copyText={props.registrationUrl} />
        </div>
        {props.warnUserNotYetCreated && <Alert variant="warning" className="mt-3">The purchaser portal for this property cannot be set up while your
          device is offline. Once the device is back online and logged in, it will create the portal. Until then, the
          links provided will not work.</Alert>}
      </Modal.Body>
      <Modal.Footer className=''>
        <Button download={'Purchaser portal Self-Registration.pdf'} href={props.pdfUrl} variant='outline-secondary'>Standard document</Button>
        <Button onClick={props.onClose} variant='primary'>Close</Button>
      </Modal.Footer>
    </Modal>
  </>;
};

import { createContext } from 'react';

export interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export type Pwa = { promptEvent: IBeforeInstallPromptEvent | null; promptToInstall: ()=>Promise<void>; };

const autoReject = ()=>(new Promise<void>((_,reject)=>{
  reject();
}));

export const PwaContext = createContext<Pwa>({
  promptEvent: null,
  promptToInstall: autoReject
});

import { OnlineContext } from '../context/online';
import { useContext } from 'react';

/**
 * Note: not guaranteed to be 100% accurate, browsers vary in their detection.
 */
export function useOnline() {
  const online = useContext(OnlineContext);
  // For components not used in an offline capable context, where this context is not set, default it to true.
  return online === null ? true : online;
}

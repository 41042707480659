import { useEffect, useRef } from 'react';
import { AsyncTypeahead, Hint, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { Predicate } from '@property-folders/common/predicate';
import clsJn from '@property-folders/common/util/classNameJoin';
import { FloatingLabel, Form, FormControl } from 'react-bootstrap';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { PathType } from '@property-folders/contract/yjs-schema/model';

export interface TypeaheadProps {
  isLoading: boolean;
  onSearch: (query: string) => void;
  promptText?: JSX.Element | string;
  searchText?: JSX.Element | string;
  debounceMs?: number

  label?: JSX.Element | string;
  onSuggestSelect?: (selection: Option[], selectedFromPath?: PathType) => void;
  onlySelectCallback?: boolean
  options: Option[]
  optionRender?: (option: Option, index: number) => JSX.Element
  extraRowData?: JSX.Element
  selectOnly?: boolean
  onChange?: (event: React.ChangeEvent<typeof FormControl>) => void
  onBlur?: (e: React.FocusEvent<typeof FormControl, Element>) => void
  onFocus?: (e: React.FocusEvent<typeof FormControl, Element>) => void
  spellCheck?: boolean
  canClear?: boolean
  onClear?: () => void
  valueType?: string;
  autocompleteLabelKey?: string

  id?: string;
  type?: string
  placeholder?: string
  maxLength?: number,
  setDefaultValue?: string,
  textArea?: {
    rows?: number,
    minHeight?: string
  } | true;
  className?: string;
  containerClassName?: string
  noBlurAction?: boolean;
  autoComplete?: string;
  defaultValueLabel?: string;
  alwaysShowDefaultSetter?: boolean;
  dateFromToday?: boolean;
  extraErrors?: string[];
  disabled?: boolean
  valuePlaceholder?: string;
  autoFocus?: boolean;

  readOnly?: boolean;
  valid?: boolean;
  textEnd?: boolean;
  hasVaried?: boolean;
  required?: boolean;
  handleUpdate?: (value: string) => void;
  value?: string|null;
  tabIndex?: number;
  emptyLabel?: React.ReactNode;
}

export function Typeahead(props: TypeaheadProps) {
  const {
    onSuggestSelect,
    readOnly = false,
    debounceMs,
    autocompleteLabelKey,
    isLoading,
    onSearch,
    promptText,
    searchText,
    id,
    options,
    placeholder,
    optionRender,
    textArea,
    setDefaultValue,
    selectOnly,
    onBlur,
    onFocus,
    className,
    valid,
    onChange,
    textEnd,
    hasVaried,
    type,
    maxLength,
    label,
    required,
    handleUpdate,
    value,
    tabIndex,
    emptyLabel,
    containerClassName
  } = props;
  let { spellCheck } = props;

  const typeaheadRef = useRef<typeof AsyncTypeahead>();
  const ownInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (ownInputRef.current && Predicate.isNotNullish(value)) {
      ownInputRef.current.value = `${value}`;
    }
  }, [value, !!ownInputRef.current]);

  const typeaheadOnChange: typeof onSuggestSelect = (...params) => {
    // There's no point clearing the async internal text field, as we tend to use this component
    // for much larger datasets where searches are paramount
    const selection = params?.[0]?.[0] as unknown;
    if (!(selection && typeof selection === 'object' && 'label' in selection)) {
      return;
    }
    if (onSuggestSelect) {
      onSuggestSelect(...params);
    } else {
      handleUpdate?.('label' in selection ? selection.label as string : '');
    }
    if (ownInputRef?.current && Predicate.isNotNullish(selection.label)) {
      ownInputRef.current.value = `${selection.label}`;
    }
  };

  return <AsyncTypeahead
    emptyLabel={emptyLabel}
    disabled={readOnly}
    ref={typeaheadRef}
    delay={debounceMs}
    labelKey={autocompleteLabelKey}
    isLoading={isLoading}
    onSearch={onSearch} // onSearch must not change every time the value is updated. Try making it a useCallback if
    // you're not getting suggestions
    promptText={promptText}
    searchText={searchText}
    id={id}
    options={options}
    onChange={typeaheadOnChange}
    highlightOnlyResult={true}
    placeholder={placeholder ?? ''}
    filterBy={() => true}
    renderMenu={(results, { renderMenuItemChildren, newSelectionPrefix, paginationText, ...menuProps }) => {
      return <Menu {...menuProps}>
        {results.map((result, index) => {
          result = typeof result === 'string' ? { label: result } : result;
          const label = optionRender ? optionRender(result, index) : result.label;

          return <MenuItem key={result.label} option={result} position={index}>{label}</MenuItem>;
        })}
      </Menu>;

    }}

    renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
      const {
        onChange: autoOnChange,
        onBlur: autoOnBlur,
        onFocus: autoOnFocus,
        value: autoValue,
        className: selectedClassName,
        autoComplete: _autoComplete,
        onClick: autoOnClick,
        ...autocompleteProps
      } = inputProps;

      const defaultMode = !value && setDefaultValue !== undefined;
      const maybeProp: {
        [prop: string]: any
      } = {};
      if (textArea) {
        maybeProp.as = 'textarea';
        if (typeof textArea === 'object' && textArea.rows) {
          maybeProp.rows = textArea.rows;
        }
      }
      if (spellCheck === undefined) {
        spellCheck = false;
      }
      maybeProp.spellCheck = spellCheck;

      const renderedValue = autoValue || value;

      return <Hint className={containerClassName}>
        <div
          className={clsJn('flex-grow-1', defaultMode && 'd-flex flex-row')}
          onClick={(e) => {
            if (readOnly) {
              return;
            }
            ownInputRef.current?.focus();
            autoOnClick?.(e);
          }}
        >

          <FloatingLabel
            className={clsJn('flex-grow-1 common-label')}
            controlId={id}
            label={required ? <span>{label} <sup style={{ color: 'red' }}>*</sup></span> : label}>
            <Form.Control
              defaultValue={renderedValue}
              ref={(node) => {
                inputRef(node);
                referenceElementRef(node);
                ownInputRef.current = node;
              }}
              spellCheck='false'
              className={clsJn(
                className,
                selectedClassName,
                selectOnly && 'fake-input-select',
                valid ? '' : 'is-invalid',
                textEnd && 'text-end',
                hasVaried && 'varied-control',
              )}
              name={name}
              type={type}
              onChange={e => {
                autoOnChange?.(e);
                if (!(selectOnly && onSuggestSelect)) {
                  onChange?.(e);
                }
              }}
              onBlur={e => {
                !(selectOnly && onSuggestSelect) && handleUpdate?.(e.target?.value);
                autoOnBlur?.(e);
                onBlur?.(e);
              }}
              onFocus={e => {
                autoOnFocus?.(e);
                onFocus?.(e);
              }}
              maxLength={maxLength ?? 300}
              {...maybeProp}
              {...autocompleteProps}
              tabIndex={tabIndex}
            />
          </FloatingLabel>
        </div>
      </Hint>;
    }}
  />;
}

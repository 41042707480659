import { WizardStepPage, WizardStepPageProps } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { ClausesSection } from '@property-folders/components/dragged-components/form/ClausesSection';
import { useContext, useEffect, useState } from 'react';
import { ClausesImportModal } from '@property-folders/components/dragged-components/form/ClausesImportModal';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { CustomClause, ImportedClause, MaterialisedPropertyData } from '@property-folders/contract';
import { uuidv4 } from 'lib0/random';
import { generateParentPath, getValueByPath } from '@property-folders/common/util/pathHandling';
import {
  insertAllImmerPath,
  useLightweightTransaction
} from '@property-folders/components/hooks/useTransactionField';
import { ClausesApi } from '@property-folders/common/client-api/clausesApi';
import { FormContext } from '@property-folders/components/context/FormContext';
import { checkBuildPath } from '@property-folders/common/util/build-skeleton';
import { Predicate } from '@property-folders/common/predicate';

import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { useReactRouterData } from '../../hooks/useReactRouterHooks';
import { RouterData } from '@property-folders/web/src/App';
import { useOnline } from '../../hooks/useOnline';

export type Props = WizardStepPageProps & TransactionConsumerProps;

export function ClausesWizardStepPage(props: Props) {
  const loaderData = useReactRouterData<RouterData>();
  const { transactionRules: dataTransRules, formName } = useContext(FormContext);
  const { fullPath, value } = useLightweightTransaction(props);
  const [showImportModal, setShowImportModal] = useState(false);
  const { updateDraft: updateRootDraft } = useYdocBinder<MaterialisedPropertyData>({ path: [] });
  const isOnline = useOnline();
  useEffect(() => {
    if (!isOnline) return;
    ClausesApi.listRequiredClausesForFormAndProperty({ formCode: formName, propertyId: loaderData.transId }).then(clauses => {
      if (!clauses?.results) {
        return;
      }

      updateRootDraft?.(draftRoot => {
        checkBuildPath(generateParentPath(fullPath), dataTransRules, draftRoot);
        const existingClauses: (ImportedClause|CustomClause)[] = getValueByPath(fullPath, draftRoot) ?? [];
        const clausesToImport: ImportedClause[] = [];

        for (const clause of clauses.results) {
          if (existingClauses && existingClauses.find(ec => 'importId' in ec && String(clause.clauseId) === String(ec.importId)) || clause.markdownClause === undefined) {
            continue;
          }

          clausesToImport.push({
            id: uuidv4(),
            title: clause.title,
            text: clause.markdownClause.replace(/[\r\n]+/g, '\n'),
            importId: String(clause.clauseId),
            required: true,
            canEdit: clause.editable,
            editing: false,
            hash: clause.hash
          });
        }

        for (const clause of existingClauses) {
          if ('importId' in clause) {
            const clauseRequired = !!clauses.results.find(r => String(r.clauseId) === clause.importId);
            if (clause.required !== clauseRequired) {
              clause.required = clauseRequired;
            }
          }
        }

        insertAllImmerPath(draftRoot, clausesToImport, fullPath, dataTransRules);
      });
    }).catch(e => {
      console.warn('Error fetching required clauses', e);
    });
  }, [formName, isOnline]);

  return <WizardStepPage
    pdfFocus='subsection-clauses'
    defaultHeaderActions={{
      clauseImport: {
        label: 'Add Standard',
        tooltip: 'Add standard Additional Condition',
        isPrimary: true,
        onClick: () => setShowImportModal(true),
        disableIfOffline: true
      }
    }}
    {...props}
  >
    <ClausesSection parentPath={props.parentPath} myPath={props.myPath} />
    <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={showImportModal} onClose={()=>setShowImportModal(false)} />}>
      <ClausesImportModal
        show={showImportModal}
        formCode={formName}
        setShow={setShowImportModal}
        propertyId={loaderData.transId}
        selected={value?.map(v => v.importId).flat().filter(Predicate.isNotNullish)}
        onAddClause={clauses => {
          updateRootDraft?.(draftRoot => {
            checkBuildPath(generateParentPath(fullPath), dataTransRules, draftRoot);
            const insertedClauses = clauses.map(c => ({
              id: uuidv4(),
              title: c.title,
              text: c.markdownClause,
              importId: String(c.clauseId),
              required: false,
              canEdit: c.editable,
              editing: false,
              hash: c.hash
            }));
            insertAllImmerPath(draftRoot, insertedClauses, fullPath, dataTransRules);
          });
        }}
      />
    </ErrorBoundary>
  </WizardStepPage>;
}

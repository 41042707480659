import { AuthApi } from '@property-folders/common/client-api/auth';
import { FormsApi } from '@property-folders/common/client-api/formsApi';
import { useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';
import { useOnline } from './useOnline';
import { ListFormsForAgent } from '@property-folders/contract/rest/forms';
import { PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import { db } from '@property-folders/common/offline/db';
import { KeywordsBuilder } from '@property-folders/common/util/fullText';

type Row = ListFormsForAgent['results'][0];

type Suburb = {
  Name: string,
  Postcode: string,
  State: string,
  LGA?: string[],
  Hundred?: string[],
  IrrigationArea?: string[]
};

type ExpectedDataStructure = {
  suburbs: Suburb[],
  hundreds: string[],
  irrigationAreas: string[],
  lgas: string[]
};

export function usePreloadOfflineData() {
  const { data: session } = AuthApi.useGetAgentSessionInfo();
  const agentId = session?.agentId ?? 0;
  const [_storageForms, setStorageForms] = useLocalStorage<Row[]>(`userForms_${agentId}`, []);
  const loadOnceRef = useRef(false);
  const isOnline = useOnline();

  useEffect(()=>{
    if (loadOnceRef.current) return;
    if (!isOnline || agentId === 0) return;
    if (!session?.featureFlags?.newPropertyFolders) return;
    loadOnceRef.current = true;
    FormsApi.listFormsForAgent().then(res => {
      setStorageForms(res.results);
    });

    PropertySearchApi.getPreloadAreaData().then(async (res) => {
      if (!res.ok) return;
      const remoteEtag = res.headers.get('Etag');

      const remoteDataPromise = res.json();
      const localEtag = (await db.offlineAreaDataLastUpdated.get('0'))?.etag;
      const remoteData = await remoteDataPromise as ExpectedDataStructure;

      if (!(!remoteEtag || localEtag !== remoteEtag)) return;

      await Promise.all([
        db.offlineAreaDataLastUpdated.clear(),
        db.hundredsData.clear(),
        db.lgaData.clear(),
        db.irrigationAreaData.clear(),
        db.suburbData.clear()
      ]);
      await Promise.all([
        db.suburbData.bulkAdd(remoteData.suburbs.map((sub,index)=> {
          const keywordsBuilder = new KeywordsBuilder();
          keywordsBuilder.add(sub.Name);
          keywordsBuilder.add(sub.State);
          keywordsBuilder.add(sub.Postcode);
          return { ...sub, id: index, keywords: keywordsBuilder.keywords() };
        })),
        db.hundredsData.bulkAdd(remoteData.hundreds.map((Name, index)=>({ Name, id: index }))),
        db.lgaData.bulkAdd(remoteData.lgas.map((Name, index)=>({ Name, id: index }))),
        db.irrigationAreaData.bulkAdd(remoteData.irrigationAreas.map((Name, index)=>({ Name, id: index })))
      ]);

      // If we don't get an etag for some reason, whatever, just store that
      await db.offlineAreaDataLastUpdated.add({ etag: `${remoteEtag}`, id: '0' });

    });
  }, [isOnline, agentId, session?.featureFlags?.newPropertyFolders]);
}

import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from './CommonComponentWrappers';
import '../Form.scss';

import { CollectionRemoveButton } from './CollectionRemoveButton';
import { portionOptionsExpanded } from '@property-folders/common/util/pdfgen/constants';
import { SaleSubTitle, SaleTitle, TitleInclusionState } from '@property-folders/contract';
import { wholePortionsOnly } from './NarrowTitle';
import { getLinkedTitles } from '@property-folders/common/yjs-schema/property/validation/expected-evaluator';
import './NarrowPlan.scss';
import { uniq } from 'lodash';

type SalesRepInputProps = TransactionConsumerProps & {
  removable?: boolean,
  hideDelete?: boolean
  allTitles?: SaleTitle[]
};
const planLotMap = {
  'Community Plan': [
    'Allotment',
    'Lot',
    'Piece',
    'Development Lot'
  ],
  'Primary Community Plan': [
    'Allotment',
    'Lot',
    'Piece',
    'Development Lot'
  ],
  'Secondary Community Plan': [
    'Allotment',
    'Lot',
    'Piece',
    'Development Lot'
  ],
  'Tertiary Community Plan': [
    'Allotment',
    'Lot',
    'Piece',
    'Development Lot'
  ],
  'Deposited Plan': [
    'Allotment',
    'Block',
    'Piece',
    'Section',
    'Unit'
  ],
  'Filed Plan': [
    'Allotment',
    'Piece'
  ],
  'Hundred': [
    'Block',
    'Section'
  ],
  'Strata Plan': [
    'Allotment',
    'Unit'
  ],
  'Town Plan': [
    'Allotment',
    'Town'
  ]
};

const allLotTypes = uniq((Object.values(planLotMap)).flatMap(a=>a));
const lotPlanMap = Object.assign({}, ...allLotTypes.map(lotType => {
  return { [lotType]: Object.entries(planLotMap).filter(([_pl, lots]) => lots.includes(lotType)).map(([planType]) => planType) };
}));

export const EditableParcel = ({ removable = true, hideDelete = false, ...restProps }: SalesRepInputProps): JSX.Element => {
  const { value, fullPath, handleRemove } = useTransactionField<SaleSubTitle>(restProps);
  const lotOptions = (planLotMap[value?.plan] ?? allLotTypes).map(s=>({ name: s, label: s }));
  const planOptions = (lotPlanMap[value?.lot] ?? Object.keys(planLotMap)).map(s=>({ name: s, label: s }));
  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;
  const options: Partial<typeof portionOptionsExpanded> = { ...portionOptionsExpanded };

  return (
    <div className="d-flex w-100 editable-parcel">
      <div className="d-flex w-100 flex-wrap align-items-center editable-parcel-items mt-2">
        {wholePortionsOnly
          ? <div className="ps-3 d-inline-block">{options[value.portionType]}</div>
          : <div className="portion-type"><WrField.Select
            label="Portion Type"
            name="portionType"
            options={options}
            valueType="int"
            parentPath={fullPath}
            myPath="portionType"
            canClear={false}
          /></div>}

        <div className="lot-type"><WrField.Select
          label={'Lot type'}
          name={'manuallot'}
          options={lotOptions}
          parentPath={fullPath}
          myPath="lot"
          unlockedSelect={true}
        /></div>
        <div className="lot-id"><WrField.Control
          label={'Lot number'}
          name="manuallotid"
          parentPath={fullPath}
          myPath="lotid"
        /></div>
        <div className="plan-type"><WrField.Select
          label={'Plan type'}
          name={'manualplan'}
          options={planOptions}
          parentPath={fullPath}
          myPath="plan"
          unlockedSelect={true}
        /></div>
        <div className="plan-id"><WrField.Control
          label={'Plan number'}
          name="manualplanid"
          parentPath={fullPath}
          myPath="planid"
        /></div>

      </div>
      {(!hideDelete) && <div className="delete-div">
        {closeButton}
      </div>}
    </div>
  );
};

export const LssaParcel = ({ removable = true, hideDelete = false, ...restProps }: SalesRepInputProps): JSX.Element => {
  const { value, fullPath, handleRemove } = useTransactionField<SaleSubTitle>(restProps);
  const parentTitle = (restProps.allTitles ?? []).find(t => (t.subTitles ?? []).find(st => value && st.id === value.id));
  const linkedTitles = getLinkedTitles(parentTitle, restProps.allTitles, true);
  const collection = linkedTitles.flatMap(t => t.subTitles);

  const options: Partial<typeof portionOptionsExpanded> = { ...portionOptionsExpanded };
  if (collection?.length === 1) {
    delete options[TitleInclusionState.none];
  }
  if (value.portionType !== TitleInclusionState.none && collection?.filter(item=>item.portionType !== TitleInclusionState.none).length === 1) {
    delete options[TitleInclusionState.none];
  }

  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;

  return (
    <div className="d-flex w-100">
      <div className='w-100'>
        <div className="d-flex w-100 flex-wrap align-items-center">
          {wholePortionsOnly
            ? null
            : <WrField.Select
              name="portionType"
              options={options}
              valueType='int'
              parentPath={fullPath}
              myPath='portionType'
              canClear={false}
            />}
          <div className='ps-3'>
            {wholePortionsOnly ? options[value.portionType] : null} {value?.lot} {value?.lotid} on {value?.plan} {value?.planid}
          </div>

        </div>
      </div>
      {(!hideDelete) && <div className='d-flex align-items-center delete-div idk'>
        {closeButton}
      </div>}
    </div>
  );
};

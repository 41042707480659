import { Button } from 'react-bootstrap';
import { InstanceCardBase } from './InstanceCardBase';
import { Link, useNavigate } from 'react-router-dom';
import { FormCardRenderOpts } from '@property-folders/common/yjs-schema/property/form';
import { clickNoBubble } from '@property-folders/common/util/clickNoBubble';
import { useContext, useMemo } from 'react';
import { YjsDocContext } from '../context/YjsDocContext';
import { generateDocumentsListFromYdoc } from '@property-folders/common/client-api/documentListApi';
import { materialisePropertyMetadata } from '@property-folders/common/yjs-schema/property';
import { navigateToRow } from '@property-folders/web/src/pages/FormsListPage';
import { CardSectionRows } from './CardSectionRows';
import { useOnline } from '../hooks/useOnline';
import { ButtonWithOfflineTooltip } from './TooltipWhenDisabled';

export function AllDocumentsCard ({
  renderOpts,
  onForceFocus,
  forceFocus,
  onUpload,
  headline
}: {
  renderOpts: FormCardRenderOpts | undefined,
  onForceFocus: (forceMe: boolean) => void,
  forceFocus?: boolean,
  onUpload: ()=>void,
  headline: string
}): JSX.Element {
  const { ydoc } = useContext(YjsDocContext);
  const navigate = useNavigate();
  const isOnline = useOnline();

  const handleUpload = () => {
    onUpload();
  };

  const meta = materialisePropertyMetadata(ydoc);

  const docs = useMemo(() => {
    return ydoc ? generateDocumentsListFromYdoc(ydoc, isOnline) : [];
  }, [meta]);

  const rows = docs?.map(d => ([
    <div className='d-flex align-items-center overflow-hidden' style={{ lineHeight: '23px' }}>
      <Link
        to={'#'}
        className='text-nowrap overflow-hidden text-black text-decoration-underline text-overflow-ellipsis'
        onClick={clickNoBubble(() => navigateToRow(d, navigate, headline))}
      >
        {d?.formName}{d?.summary ? ` - ${d?.summary}` : ''}
      </Link>
    </div>])
  );

  return <InstanceCardBase
    title="All Documents"
    isProcess={renderOpts?.processNotForm}
    clickable={true}
    onOpen={() => navigate('documents')}
    iconBefore={renderOpts?.iconBefore}
    iconAfter={renderOpts?.iconAfter}
    footerButtons={<>
      <ButtonWithOfflineTooltip variant='outline-secondary' onClick={clickNoBubble(handleUpload)}>Upload</ButtonWithOfflineTooltip>
      <Button variant='outline-secondary' onClick={clickNoBubble(() => navigate('documents'))}>View</Button>
    </>}
    forceFocus={forceFocus}
  >
    <CardSectionRows rows={rows} maxRows={13}/>
  </InstanceCardBase>;
}

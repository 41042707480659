import { useContext, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import './IosInstallAppHint.scss';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import clsJn from '@property-folders/common/util/classNameJoin';
import { CloseButton } from 'react-bootstrap';
import { PwaContext } from '../context/pwa';
import { useDispatch, useSelector } from 'react-redux';
import { setIosInstructionHighlight } from '@property-folders/common/redux-reducers/other-ui';

function isTouchDevice() {
  // derived from https://stackoverflow.com/questions/4817029/whats-an-optimal-or-efficient-way-to-detect-a-touch-screen-device-using-javas
  const noHover = window.matchMedia('(hover: none)').matches;
  // Any pointer not being usable seems to hold up
  const mainPointerTouch = window.matchMedia('(pointer: coarse)').matches;
  return noHover || mainPointerTouch;
}

function isNewIpad() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const res = isTouchDevice() && /macintosh.+mac.+safari/.test(userAgent);
  return res;
}

export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const ipad = /ipad/.test( userAgent ) || isNewIpad();
  const anyIdev = ipad || /iphone|ipod/.test( userAgent );
  if (!anyIdev) return null;
  return { ipad };
};
// Detects if device is in standalone mode
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

export function IosInstallAppHint() {
  const dispatch = useDispatch();
  const sessionStateRaw = AuthApi.useLoggingOnSessionInfo();
  const [showPrompt, setShowPrompt] = useState(false);
  const [ipad, setIpad] = useState(false);
  const pwa = useContext(PwaContext); // In case the install prompt becomes available in a later safari version

  // We share this localstorage vart with the on-page button, because if it does pop up for safari
  // in future, they've already dismissed it...
  const [dismissed, setDismissed] = useLocalStorage('dismissedIosInstallHint', false);

  const highlight = useSelector(state => ((state as any)?.otherUi?.highlightIosInstallInstruction));

  useEffect(() => {
    const is = isIos();
    if (!is) return;
    if (is && !isInStandaloneMode()) {
      setShowPrompt(true);
    }
    if (is.ipad) {
      setIpad(true);
    }
  }, []);

  if (!(
    !pwa?.promptEvent
    && sessionStateRaw.data.session?.type === 'agent'
    && sessionStateRaw.data.session.featureFlags?.newPropertyFolders
    && (highlight || (showPrompt && !dismissed))
  )) {
    return <></>;
  }

  const dismissAction = highlight
    ? ()=>dispatch(setIosInstructionHighlight({ enable: false }))
    : ()=>setDismissed(true);

  const corePrompt = <div className={clsJn('ios-install-prompt-container', ipad && 'ipad')}>
    <div className='ios-install-prompt'>
      <div className='pad-left'></div>
      <div className='prompt-body'>Install the Greatforms app on your {ipad ? 'iPad' : 'iPhone'}. Tap <div
        className='share-icon-div'><img alt='iOS share icon' src={LinkBuilder.propertiesImages('ios-share-icon.png')}/>
      </div> and then Add to homescreen.
      </div>
      <div className='pad-right'><CloseButton onClick={dismissAction}/></div>
    </div>
    <div className='ios-arrow'></div>
  </div>;

  return <>
    {highlight && <div className="ios-install-prompt-overlay" onClick={dismissAction}/>}

    {corePrompt}
  </>;
}

import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { useCallback, useContext, useRef, useState } from 'react';
import { PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import { WrField } from './CommonComponentWrappers';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { Suggestion } from './Suggestor';
import { IAddressSearchResponse, IAddressSearchResponseParts, IPropertySearchSuggestions } from '@property-folders/contract/rest/address-search';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { generateParentPath, normalisePathToStrArray } from '@property-folders/common/util/pathHandling';
import {
  composeFullAddressFromParts
} from '@property-folders/common/util/formatting/string-composites';
import { LinkBuilderContext } from '../../context/link-builder-context';
import { useOnline } from '../../hooks/useOnline';
import { OFFLINE_TEXT_SHORT } from '@property-folders/common/data-and-text/constants';

export interface AddressSelectorProps extends TransactionConsumerProps {
  label: string;
  gnafCentre?: string;
  international?: boolean;
  autoFocus?: boolean;
  setAutoComplete?: boolean;
}

export const AddressSelector = ({ label, parentPath, myPath, gnafCentre, international, autoFocus, setAutoComplete }: AddressSelectorProps): JSX.Element => {
  const linkBuilder = useContext(LinkBuilderContext);
  const { fullPath } = useLightweightTransaction({ parentPath, myPath });
  const pathSegs = normalisePathToStrArray(fullPath);
  const leafPath = pathSegs[pathSegs.length-1];
  const { updateDraft } = useYdocBinder({ path: generateParentPath(fullPath) });
  const isOnline = useOnline();
  const [suggestions, setSuggestions] = useState([] as Suggestion[]);
  const [isSearching, setIsSearching] = useState(0);
  const activeSearch = useRef<{abort: ()=>void, response: Promise<IPropertySearchSuggestions | undefined>}>();
  const searchFunc = useCallback((query: string) => {
    activeSearch.current?.abort();
    if (!query || query.trim() === '') {
      return;
    }

    setIsSearching(s => s + 1);
    activeSearch.current = PropertySearchApi.getSuggestions({
      searchTerm: query,
      limitToSa: false,
      gnafCentre,
      international,
      linkBuilder
    });
    activeSearch.current.response.then((suggestions) => {
      setSuggestions(suggestions?.Predictions.slice(0, 10).map(s => ({
        label: s.AddressParts ? composeFullAddressFromParts(s.AddressParts) : s.FullAddress,
        ...s
      })) ?? []);
    }).catch(e => {
      console.error(e);
    }).finally(() => {
      setIsSearching(s => s - 1);
    });
  },[gnafCentre, international]);

  function handleSuggestionSelect([suggestion]: [IAddressSearchResponse]) {
    function updateParts(parts: IAddressSearchResponseParts) {
      updateDraft?.(draft=>{
        if (!draft) return false;
        draft[`${leafPath}_parts`] = parts;
        if (parts.Country && parts.Country !== 'Australia') return false;
        draft[leafPath] = composeFullAddressFromParts(parts);
      });
    }
    if (suggestion.AddressParts) {
      const parts = suggestion.AddressParts;
      updateParts(parts);
      return;
    }

    if (suggestion.DetailCallback) {
      PropertySearchApi.getDetail(suggestion.DetailCallback.EndpointParams, linkBuilder).response
        .then(({ Predictions: [addressResult] }) => {
          const parts = (addressResult as IAddressSearchResponse).AddressParts;
          updateParts(parts);
        });
      return;
    }
  }

  return <WrField.AsyncAutoComplete
    emptyLabel={isOnline ? null : `${OFFLINE_TEXT_SHORT}.`}
    debounceMs={0}
    label={label}
    isLoading={isSearching > 0}
    options={suggestions}
    autoFocus={autoFocus}
    parentPath={parentPath}
    myPath={myPath}
    name={fullPath}
    onSearch={searchFunc}
    promptText={'Type address to search'}
    searchText={'Searching for address'}
    onSuggestSelect={handleSuggestionSelect }
    filterBy={() => true} // disable local filtering
    autoComplete={setAutoComplete ? 'street-address' : undefined}
  />;

};

import { OFFLINE_TEXT_SHORT } from '@property-folders/common/data-and-text/constants';
import { useOnline } from '../hooks/useOnline';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AsyncButton, SpinnerButton } from './AsyncButton';
import { Link } from 'react-router-dom';
import clsJn from '@property-folders/common/util/classNameJoin';

export function TooltipWhenRequired(props: {children: JSX.Element[]|JSX.Element|string , title: string, required: boolean}) {
  if (props.required && props.title) {
    return <OverlayTrigger trigger={'click'} rootClose={true} overlay={<Tooltip id={'tooltip-disabled'}>{props.title}</Tooltip>}>
      <span className={'d-inline-block cursor-pointer'}>
        {props.children}
      </span>
    </OverlayTrigger>;
  }

  return <>{props.children}</>;
}

export function ButtonWithOfflineTooltip(props: Parameters<typeof Button>[0] & {offlineCheckDisabled?: boolean, tooltipBelow?: boolean}) {
  const { offlineCheckDisabled, tooltipBelow, ...buttonProps } = props;
  const isOnline = useOnline();
  if (!isOnline && !offlineCheckDisabled) {
    return <OverlayTrigger placement={tooltipBelow ? 'bottom' : 'top'} trigger={'click'} rootClose={true} overlay={<Tooltip id={'tooltip-disabled'}>{OFFLINE_TEXT_SHORT}</Tooltip>}>
      <span className={'d-inline-block cursor-pointer'}>
        <Button {...buttonProps} disabled={true} />
      </span>
    </OverlayTrigger>;
  }
  return <Button {...buttonProps} />;
}

export function SpinnerButtonWithOfflineTooltip(props: Parameters<typeof SpinnerButton>[0]) {
  const isOnline = useOnline();
  if (!isOnline) {
    return <OverlayTrigger trigger={'click'} rootClose={true} overlay={<Tooltip id={'tooltip-disabled'}>{OFFLINE_TEXT_SHORT}</Tooltip>}>
      <span className={'d-inline-block cursor-pointer'}>
        <SpinnerButton {...props} disabled={true} />
      </span>
    </OverlayTrigger>;
  }
  return <SpinnerButton {...props} />;
}

export function AsyncButtonWithOfflineTooltip(props: Parameters<typeof AsyncButton>[0]) {
  const isOnline = useOnline();
  if (!isOnline) {
    return <OverlayTrigger trigger={'click'} rootClose={true} overlay={<Tooltip id={'tooltip-disabled'}>{OFFLINE_TEXT_SHORT}</Tooltip>}>
      <span className={'d-inline-block cursor-pointer'}>
        <AsyncButton {...props} disabled={true} />
      </span>
    </OverlayTrigger>;
  }
  return <AsyncButton {...props} />;
}
export function DropdownItemWithOfflineTooltip(props: Parameters<typeof Dropdown.Item>[0] & {offlineCheckEnabled?: boolean}) {
  const { offlineCheckEnabled, ...dropdownItemProps } = props;
  const isOnline = useOnline();
  if (!isOnline && offlineCheckEnabled) {
    dropdownItemProps.className = dropdownItemProps.className?.replace('text-dark', '');
    return <OverlayTrigger trigger={'click'} rootClose={true} overlay={<Tooltip id={'tooltip-disabled'}>{OFFLINE_TEXT_SHORT}</Tooltip>}>
      <span className={''}>
        <Dropdown.Item {...dropdownItemProps} disabled={true} style={{ color: 'grey !important' }}/>
      </span>
    </OverlayTrigger>;
  }
  return <Dropdown.Item {...dropdownItemProps} />;
}

export function LinkWithOfflineTooltip(props: Parameters<typeof Link>[0]) {
  const isOnline = useOnline();
  if (!isOnline) {
    return <OverlayTrigger trigger={'click'} rootClose={true} overlay={<Tooltip id={'tooltip-disabled'}>{OFFLINE_TEXT_SHORT}</Tooltip>}>
      <span className={'d-inline-block cursor-pointer'}>
        <Link {...props} className={clsJn(props.className, 'disabled-offline-link')} onClick={undefined}  />
      </span>
    </OverlayTrigger>;
  }
  return <Link {...props} />;
}
import { useRef } from 'react';
import { useTimeout } from '../useTimeout';
import { EnvelopeApi } from '@property-folders/common/client-api/envelopeApi';
import { useOnline } from '../useOnline';

export class EnvelopeApiHooks {
  static useExternalUpdateWatcher(propertyId?: string) {
    const ref = useRef({ hasUpdate: false, lastUpdate: 0, delayValue: 1 });
    const isOnline = useOnline();
    const checkForUpdates = () => {
      if (!(propertyId && isOnline)) {
        return;
      }

      const nowInSecs = Date.now() / 1000;
      if (nowInSecs - ref.current.lastUpdate > 300) {
        EnvelopeApi.checkForExternalUpdates(propertyId)
          .then(val => {
            ref.current = {
              ...ref.current,
              hasUpdate: val
            };
          })
          .catch(e => {
            console.warn(e);
          })
          .finally(() => {
            ref.current = {
              ...ref.current,
              lastUpdate: nowInSecs
            };
          });
      } else {
        ref.current = {
          ...ref.current,
          delayValue: 30
        };
      }
    };

    useTimeout(checkForUpdates, ref.current.delayValue, ref.current.lastUpdate);

    return ref.current.hasUpdate;
  }
}

import { ChangeEvent, useCallback, useState } from 'react';
import { WrField } from './CommonComponentWrappers';
import { db } from '@property-folders/common/offline/db';

interface Props {
  myPath: string;
  parentPath: string;
  onChange?: (e: ChangeEvent<any>) => void;
}

export function Lga({ myPath, parentPath, onChange }: Props) {
  const [isSearching, setIsSearching] = useState(false);
  const [suggestions, setSuggestions] = useState([] as {label: string}[]);

  const search = useCallback((query: any) => {
    if (!query || query.trim() === '') {
      setSuggestions([]);
      return;
    }
    setIsSearching(true);
    const qRes = db.lgaData.where('Name').startsWithIgnoreCase(query.trim()).limit(10).toArray();
    qRes.then((resultSuggestions: any[]) => {
      setSuggestions(resultSuggestions.map(r=>({ label: r.Name })));
    }).finally(() => {
      setIsSearching(false);
    });

  }, []);

  return <WrField.AsyncAutoComplete
    name='LGA'
    label='LGA'
    parentPath={parentPath}
    myPath={myPath}
    options={suggestions || []}
    isLoading={isSearching}
    onSearch={search}
    onChange={e => { onChange?.(e); }}
  />;
}

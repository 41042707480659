import { IBeforeInstallPromptEvent, Pwa } from 'context/pwa';
import { useState, useEffect, useMemo } from 'react';

export function useAddToHomescreenPrompt(): Pwa {
  const [prompt, setPrompt] = useState<IBeforeInstallPromptEvent | null>(null);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {

      e.preventDefault();
      setPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', ready as any);

    return () => {

      window.removeEventListener('beforeinstallprompt', ready as any);
    };
  }, []);

  return useMemo(()=>({ promptEvent: prompt, promptToInstall }), [prompt]);
}